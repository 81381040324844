<template>
  <page-layout
  >
    <div
      class="h-100 w-100 d-flex justify-content-center align-items-center"
    >
      <b-card
        class="shadow border-0"
      >
        <b-row
          no-gutters
        >
          <b-col
            align-self="center"
          >
            <b-card-title
              class="text-center font"
            >
              {{ messages.getLabelCurrentUserExpired() }}
            </b-card-title>
            <b-button
              block
              class="font m-2"
              href="/"
            >
              {{ messages.getActionCheckExpirationAgain() }}
            </b-button>
            <b-button
              block
              class="font m-2"
              @click="signOut"
            >
              {{ messages.getActionSignOut() }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </page-layout>
</template>

<script>
import { messages } from '@/utils/strings'
import PageLayout from '../components/PageLayout'
import { signOutMixin } from '../components/sign-out-mixin'

export default {
  name: 'UserExpired',
  components: { PageLayout },
  mixins: [signOutMixin],
  data () {
    return {
      messages
    }
  }
}
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@include media-breakpoint-down(sm) {
  .shadow {
    box-shadow: none!important;
  }
}
.font {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}
.card {
  max-width: 30rem;
}
</style>
